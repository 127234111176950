import React from 'react';
import ReactPlayer from 'react-player';
import * as easings from 'd3-ease';
import { Spring, animated } from 'react-spring/renderprops';

import { Image, SvgLib } from '_components';

class Intro extends React.PureComponent {
  state = {
    opacity: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    x: 0,
    y: 0,
    logo: 'show',
    logoColor: 'white'
  };

  constructor(props) {
    super(props);
    this.oneTimeout = null;
    this.twoTimeout = null;
    this.threeTimeout = null;
    this.fourTimeout = null;
    this.fiveTimeout = null;

    this.step = 0;
  }

  componentDidMount() {
    const { page = false } = this.props;
    const { hero = false } = page;
    const { video = false } = hero;

    if (!video) {
      this.startAnim();
    }
  }

  componentWillUnmount() {
    if (this.oneTimeout) {
      clearTimeout(this.oneTimeout);
    }
    if (this.twoTimeout) {
      clearTimeout(this.twoTimeout);
    }
    if (this.threeTimeout) {
      clearTimeout(this.threeTimeout);
    }
    if (this.fourTimeout) {
      clearTimeout(this.fourTimeout);
    }
    if (this.fiveTimeout) {
      clearTimeout(this.fiveTimeout);
    }
  }

  startAnim = () => {
    this.setState({
      opacity: 1
    });


    this.oneTimeout = setTimeout(() => {
      this.setState({
        logo: 'hide'
      });
    }, 2000);

    this.twoTimeout = setTimeout(() => {
      const containerBounds = this.container.getBoundingClientRect();

      this.setState({
        width: containerBounds.width,
        x: containerBounds.left
      });
    }, 3000);

    this.threeTimeout = setTimeout(() => {
      const containerBounds = this.container.getBoundingClientRect();

      this.setState({
        height: containerBounds.height,
        y: containerBounds.top
      });
    }, 3550);

    this.fourTimeout = setTimeout(() => {
      this.setState({
        logo: 'show',
        logoColor: 'black'
      });
    }, 4050);

    this.fiveTimeout = setTimeout(() => {
      this.props.finishIntro();
    }, 4750);

    // this.step = this.step + 1;
  };

  // render
  render() {
    const { page = false, isHome = false } = this.props;
    const { hero = false } = page;
    const { image = false, video = false, videoMobile = false } = hero;

    const to = {
      opacity: this.state.opacity,
      width: this.state.width,
      height: this.state.height,
      transform: `translate3d(${this.state.x}px, ${this.state.y}px, 0)`
    };

    //render
    return (
      <div className={isHome ? `Intro is-Home` : `Intro`}>
        <Spring
          native
          to={to}
          config={{ duration: 500, easing: easings.easeCubic }}
        >
          {({ opacity, width, height, transform }) => (
            <animated.div
              className={`Intro__inner`}
              style={{
                opacity: opacity.interpolate(opacity => opacity)
              }}
            >
              {this.renderLogo()}
              {this.renderFinals()}
              <animated.div
                className={`Intro__img`}
                style={{
                  width: width.interpolate(width => width),
                  height: height.interpolate(height => height),
                  transform: transform.interpolate(transform => transform)
                }}
              >
                {video && (
                  <div className={`Intro__video`}>
                    <ReactPlayer
                      playsinline
                      muted={true}
                      controls={false}
                      loop={true}
                      pip={false}
                      playing={true}
                      url={
                        videoMobile && window.innerWidth < 700
                          ? videoMobile.url
                          : video.url
                      }
                      onReady={e => this.startAnim()}
                    />
                  </div>
                )}

                {image && <Image image={image} byPassRatio={true} />}
              </animated.div>
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }

  renderLogo() {
    return (
      <div className={'Logo Logo--intro'}>
        <div className={`Logo__space`} />

        <div
          className={
            `Logo__logo Logo__logo--` +
            this.state.logoColor +
            ` Logo__logo--` +
            this.state.logo
          }
        >
          <div className={`Logo__logoInner`}>
            <div className={`Logo__logoTop`}>
              <SvgLib svg={`logoTop`} />
            </div>
            <div className={`Logo__logoBottom`}>
              <SvgLib svg={`logoBottom`} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFinals() {
    return (
      <div className={`Intro__final`}>
        <div className={`Intro__finalInner`}>
          <div
            className={`Intro__finalMain`}
            ref={el => (this.container = el)}
          ></div>
        </div>
      </div>
    );
  }
}
export default Intro;

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { MainRoute } from '_views';

import './App.scss';

const App = props => (
  <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={routeProps => <MainRoute {...routeProps} app={props.app} />}
      />
      <Route
        exact
        path="/:uid"
        render={routeProps => <MainRoute {...routeProps} app={props.app} />}
      />
      <Route
        render={routeProps => <MainRoute {...routeProps} app={props.app} />}
      />
    </Switch>
  </Router>
);

export default App;

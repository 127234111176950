import React from 'react';
import { Menu, Logo } from '_components';

class Header extends React.PureComponent {
  // render
  render() {
    const { headline = false } = this.props;
    const classOpenMenu = this.props.menuOpen ? ` menu-open` : ``;

    return (
      <div className={'Header' + classOpenMenu}>
        <div className={'Header__top'}>
          <div className="Hide__wrap">
            <div className="Hide__inner">{headline}</div>
          </div>
        </div>

        <Menu
          {...this.props}
          menuOpen={this.props.menuOpen}
          menuToggle={this.props.menuToggle}
        />
        <Logo
          {...this.props}
          menuOpen={this.props.menuOpen}
          menuToggle={this.props.menuToggle}
        />

        <div
          className={`Header__btn`}
          onClick={() => this.props.menuToggle('toggle')}
        >
          <div className="Hide__wrap">
            <div className="Hide__inner">
              <div className={`Header__btnInner`}>
                <span>{this.props.menuOpen ? 'Close' : 'Menu'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Header;

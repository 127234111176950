import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { useTransition, useSpring, useChain, animated } from 'react-spring';

import { SvgLib } from '_components';

const Menu = props => {
  const {
    menuOpen = false,
    address = {},
    pages = {},
    contacts = false,
    headline = false
  } = props;

  const allDivs = [
    { title: contacts },
    { title: headline },
    ...pages,
    ...address
  ];

  const totalDivs = allDivs.length;

  const backgroundRef = useRef();
  const { transform, ...rest } = useSpring({
    ref: backgroundRef,
    from: { transform: `translateX(100%)` },
    to: { transform: menuOpen ? `translateX(0%)` : `translateX(100%)` }
  });

  const menuRef = useRef();
  const menuTrail = useTransition(menuOpen ? allDivs : [], item => item.title, {
    ref: menuRef,
    unique: true,
    reverse: !menuOpen ? true : false,
    trail: 1000 / totalDivs,
    from: { x: -100 },
    enter: { x: 0 },
    leave: { x: -100 }
  });

  useChain(menuOpen ? [backgroundRef, menuRef] : [menuRef, backgroundRef], [
    0,
    1
  ]);

  return (
    <animated.div className={'Menu'} style={{ ...rest, transform: transform }}>
      <div className={`Menu__top`}>
        <div className={`Menu__wrap`}>
          <animated.div
            style={{
              transform: menuTrail[0]
                ? menuTrail[0].props.x.interpolate(
                    x => `translate3d(0,${x}%,0)`
                  )
                : `translate3d(0,-100%,0)`
            }}
          >
            <div
              className={`Menu__top--headline`}
              dangerouslySetInnerHTML={{ __html: headline }}
            />
            <div
              className={`Menu__top--contacts`}
              dangerouslySetInnerHTML={{ __html: contacts }}
            />
          </animated.div>
        </div>
      </div>

      <div className={`Menu__bottom`}>
        <div className={`Menu__wrap`}>
          <animated.div
            style={{
              transform: menuTrail[totalDivs - 1]
                ? menuTrail[totalDivs - 1].props.x.interpolate(
                    x => `translate3d(0,${x}%,0)`
                  )
                : `translate3d(0,-100%,0)`
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: headline }} />
          </animated.div>
        </div>
      </div>

      <ul className={`Menu__nav`}>
        {pages &&
          pages.map((item, i) => {
            return (
              <li key={`menu__nav--` + i}>
                <animated.div
                  className={`Menu__navInner`}
                  style={{
                    transform: menuTrail[i + 1]
                      ? menuTrail[i + 1].props.x.interpolate(
                          x => `translate3d(0,${x}%,0)`
                        )
                      : `translate3d(0,-100%,0)`
                  }}
                >
                  <Link
                    to={'/' + item.slug}
                    onClick={() => props.menuToggle(false)}
                  >
                    <SvgLib svg={`arrow`} />
                    <span>{item.title}</span>
                  </Link>
                </animated.div>
              </li>
            );
          })}
      </ul>
      <div className={`Menu__address`}>
        {address &&
          address.map((item, i) => {
            return (
              <div key={`menu__address--` + i} className={`Menu__addressItem`}>
                <div className={`Menu__wrap`}>
                  <animated.div
                    className={`Menu__addressItemInner`}
                    style={{
                      transform: menuTrail[i + pages.length + 1]
                        ? menuTrail[i + pages.length + 1].props.x.interpolate(
                            x => `translate3d(0,${x}%,0)`
                          )
                        : `translate3d(0,-100%,0)`
                    }}
                  >
                    {item.location && (
                      <div className={`Menu__addressLocation`}>
                        {item.location}
                      </div>
                    )}
                    {item.address && (
                      <div dangerouslySetInnerHTML={{ __html: item.address }} />
                    )}
                  </animated.div>
                </div>
              </div>
            );
          })}
      </div>
    </animated.div>
  );
};

export default Menu;

/**
 *
 * Library of reusable functions
 * --------------------------------
 *
 * Useable by importing them from _utils
 *
 */

// --- Constants ---

export const breakpoints = {
  small: 768,
  medium: 1024,
  large: 1200,
  xlarge: 1400,
  xxlarge: 1600
};

// export {breakpoints,} from './config/breakpoints'
export { svgLibrary } from './config/svgLibrary';

// --- Functions ---

export { scrollTo } from './functions/scrollTo';
export { ratioPercentage } from './functions/ratioPercentage';
// export {
//     isMobile,
//     setHtmlOverflow,
// } from './functions/sethtmloverflow'

// export {
//     resizer,
// } from './functions/resizer'

export { diff, clamp, map } from './functions/math';

export {
  mergeClassNames,
  refToArray,
  bind,
  capitalize,
  randomString,
  getItem,
  getNextItem,
  getCategories,
  getAuthors,
  getLink,
  getImages,
  splitText
} from './functions/helpers';

// export { ajax } from './request/ajax'
// export { fetchRequired } from './request/fetchRequired'
// export { fillUrlArguments } from './request/fillUrlArguments'
// export { getStaticAssetUrl } from './request/getStaticAssetUrl'
// export { serialize } from './request/serialize'
// export { urlMatchesPattern } from './request/urlMatchesPattern'

import React, { Fragment } from 'react';

// import { Helmet } from 'react-helmet';

import { Hero } from '_components';

// Declare your component
class Page extends React.PureComponent {
  render() {
    return (
      <div className={'Page'}>
        <Hero {...this.props} />
        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    const { body = false } = this.props;

    return (
      <div className={`Page__wrap`}>
        {body &&
          body.map((item, i) => {
            const { type = false } = item;
            return (
              <div
                key={`body--` + i}
                className={`Page__body Page__body--` + type}
              >
                {this.renderInside(item, type)}
              </div>
            );
          })}
      </div>
    );
  }

  renderInside(item, type) {
    switch (type) {
      case 'profile':
        return (
          <Fragment>
            {item.name && (
              <div className={`Page__col Page__col--lg Page__text`}>
                <div dangerouslySetInnerHTML={{ __html: item.name }} />
                {item.contacts && (
                  <div
                    className={`Page__profileContacts`}
                    dangerouslySetInnerHTML={{ __html: item.contacts }}
                  />
                )}
              </div>
            )}
            {item.text && (
              <div
                className={`Page__col Page__smText`}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            )}
          </Fragment>
        );
      case 'titleAndText':
        return (
          <Fragment>
            {item.title && (
              <div className={`Page__col Page__mdText`}>
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
              </div>
            )}
            {item.text && (
              <div
                className={`Page__col Page__col--lg Page__text`}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            )}
          </Fragment>
        );
      case 'privacyBlocks':
        return (
          <Fragment>
            {item.title && (
              <div className={`Page__col Page__col--lg Page__text`}>
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
              </div>
            )}
            {item.text && (
              <div
                className={`Page__col Page__smText`}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            )}
          </Fragment>
        );
      default:
        return (
          <Fragment>
            {item.text && (
              <div className={`Page__col Page__col--lg Page__text`}>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            )}
            {item.sideText && (
              <div
                className={`Page__col Page__smText`}
                dangerouslySetInnerHTML={{ __html: item.sideText }}
              />
            )}
          </Fragment>
        );
    }
  }
}

export default Page;

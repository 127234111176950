import React from 'react';

import { svgLibrary } from '_utils';

class SvgLibrary extends React.PureComponent {
  getSvg(svgName) {
    return svgLibrary[svgName] ? svgLibrary[svgName] : false;
  }

  render() {
    const svg = this.getSvg(this.props.svg);

    return (
      <svg
        {...{
          className: this.props.className ? this.props.className : ``,
          viewBox: svg.viewBox ? svg.viewBox : `0 0 120 120`,
          style: { ...this.props.style }
        }}
      >
        {this.renderShape(svg.inner)}
      </svg>
    );
  }

  renderShape(shape) {
    if (!shape) return false;
    if (typeof shape !== `string`) return shape;
    return <path d={shape} />;
  }
}

export default SvgLibrary;

import React from 'react';
import 'whatwg-fetch';
import App from '../App';

export default class ApiApp extends React.Component {
  // url = `http://localhost/web/globals.json`; // TODO: change in production
  url = `https://adeptive.co/web/globals.json`;

  state = {
    error: null,
    isLoaded: false,
    main: false,
    apiUrl: false
  };

  componentDidMount() {
    fetch(`${this.url}`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            main: result.items[0],
            apiUrl: result.home_page_url
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    return <App app={this.state} />;
  }
}

import React from 'react';

import {} from '_components';
import {} from '_utils';

// Declare your component
class Home extends React.PureComponent {
  render() {
    return <div className={'Home'}>Home</div>;
  }
}

export default Home;

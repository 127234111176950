import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

import useMeasure from 'use-measure';
import { SvgLib } from '_components';

const Logo = props => {
  const { contacts = false } = props;

  const measureRef = useRef();
  const measurement = useMeasure(measureRef);

  const [isSticky, setSticky] = useState(false);
  const [isExpand, setExpand] = useState(0);
  const [hideArrow, setHideArrow] = useState(false);
  const [hideExtraSpace, setHideExtraSpace] = useState(false);

  const handleScroll = e => {
    setHideArrow(window.scrollY >= 100 ? true : false);
    setHideExtraSpace(window.scrollY >= 1 ? true : false);
    setSticky(window.scrollY >= measurement.height ? true : false);
    setExpand(
      window.scrollY >= measurement.height
        ? measurement.height
        : measurement.height + window.scrollY - measurement.height
    );
  };

  // effets
  const classSticky = isSticky ? ' is-fixed' : '';
  const styleExtraSpace = useSpring({ height: props.menuOpen ? isExpand : 0 });

  //handle Scroll
  useEffect(() => {
    if (measurement.height) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [measurement.height]);

  // render

  return (
    <div className={'Logo'}>
      <div
        className={
          hideArrow ? 'Header__arrow Header__arrow--hide' : 'Header__arrow'
        }
      >
        <SvgLib svg={`arrowDown`} />
      </div>

      <div className={`Logo__space`} ref={measureRef} />

      <div className={`Logo__logo` + classSticky}>
        <div className={`Logo__contacts`}>
          <div className="Hide__wrap">
            <div className="Hide__inner">
              <div dangerouslySetInnerHTML={{ __html: contacts }} />
            </div>
          </div>
        </div>

        <div className={`Logo__headline`}>
          <div className="Hide__wrap">
            <div className="Hide__inner">
              <div dangerouslySetInnerHTML={{ __html: contacts }} />
            </div>
          </div>
        </div>
        {hideExtraSpace && (
          <animated.div
            className={`Logo__spaceExtra`}
            style={styleExtraSpace}
          />
        )}
        <div className={`Logo__logoInner`}>
          <Link
            to={'/'}
            className={`Logo__logoTop`}
            onClick={() => props.menuToggle(false)}
          >
            <SvgLib svg={`logoTop`} />
          </Link>
          <Link
            to={'/'}
            className={`Logo__logoBottom`}
            onClick={() => props.menuToggle(false)}
          >
            <SvgLib svg={`logoBottom`} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Logo;

import React from 'react';
import ReactPlayer from 'react-player';

import { Image } from '_components';

const Hero = props => {
  const { title = '', hero = false, isHome = false } = props;
  const { image = false, video = false, videoMobile = false } = hero;

  //render
  return (
    <div className={isHome ? `Hero is-Home` : `Hero`}>
      {!isHome && (
        <h1 className={`Hero__title`}>
          <div className="Hide__wrap">
            <div className="Hide__inner">{title}</div>
          </div>
        </h1>
      )}

      <div className={`Hero__inner`}>
        <div className={`Hero__img`}>
          {video && (
            <div className={`Hero__video`}>
              <ReactPlayer
                playsinline
                muted={true}
                controls={false}
                loop={true}
                pip={false}
                playing={true}
                url={
                  videoMobile && window.innerWidth < 700
                    ? videoMobile.url
                    : video.url
                }
              />
            </div>
          )}

          {image && <Image image={image} byPassRatio={true} />}
        </div>
      </div>
    </div>
  );
};

export default Hero;

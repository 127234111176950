import React from 'react';
import Observer from 'react-intersection-observer';

class Image extends React.PureComponent {
  state = {
    src: false
  };

  componentDidMount() {
    if (!this.props.forceInView) return;

    this.setState({
      src: this.getKirbyImage()
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.image || prevProps.image.url === this.props.image.url)
      return;
    const newSrc = this.getKirbyImage();

    if (prevState.src && (!this.state.src || newSrc === this.state.src)) return;

    return this.setState({
      src: newSrc
    });
  }

  /**
   * Event Handlers
   */
  toggleInView = inView => {
    if (this.state.src || inView === false || !this.props.image) return;

    this.setState({
      src: this.getKirbyImage()
    });
  };

  /**
   * Renders
   */

  render() {
    const { image, byPassRatio = false } = this.props;
    const { url = ``, alt = `` } = image;

    const byPassClass = byPassRatio ? 'Image--full' : 'Image';
    const propsClass = this.props.className ? this.props.className : ``;

    return (
      <Observer
        className={byPassClass + ' ' + propsClass}
        onChange={this.toggleInView}
        threshold={0}
        rootMargin="0% 0% -5%"
        triggerOnce={true}
        style={this.getContainerStyle()}
      >
        {typeof document === `undefined` && (
          <noscript>
            <img src={url} alt={alt} />
          </noscript>
        )}

        <div
          className={!this.state.src ? 'Image__src' : 'Image__loadedSrc'}
          ref={el => (this.container = el)}
          style={{
            ...this.props.style,
            backgroundImage: this.state.src ? `url(${this.state.src})` : ``
          }}
          {...this.props.events}
        />

        {this.props.children ? this.props.children : false}
      </Observer>
    );
  }

  /**
   * Helpers
   */

  getContainerStyle = () => {
    const { image, ratio = false, byPassRatio = false } = this.props;

    if (byPassRatio) return {};

    const padding = ratio ? ratio : image.ratio ? (1 / image.ratio) * 100 : 100;
    return {
      paddingBottom: `${padding}%`
    };
  };

  getKirbyImage(image = this.props.image) {
    if (!image) return false;

    const { url, src } = image;
    const imageWidth = this.container.clientWidth;

    if (src) {
      if (imageWidth < 300 && src[300]) {
        return src[300];
      } else if (imageWidth < 800 && src[800]) {
        return src[800];
      } else if (imageWidth < 1000 && src[1000]) {
        return src[1000];
      }
    }

    return url;
  }
}

export default Image;

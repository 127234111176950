import React from 'react';

export const svgLibrary = {
  /**
   * Logotype
   */
  logo: {
    viewBox: `0 0 150 60`,
    inner: (
      <g>
        <path d="M9.4,0.1h3.9l9.4,24.2H19l-2.4-6H5.9l-2.3,6H0L9.4,0.1z M15.5,15.2l-4.2-11L7,15.2H15.5z"></path>
        <path d="M26.1,0.1h8.6c2.5,0,4.6,0.5,6.4,1.5c1.8,1,3.1,2.4,4,4.2s1.4,4,1.4,6.5s-0.5,4.6-1.4,6.5c-0.9,1.8-2.2,3.2-4,4.2   c-1.8,0.9-3.9,1.5-6.4,1.5h-8.6V0.1z M34.7,21.2c2.6,0,4.6-0.8,6.1-2.3s2.2-3.8,2.2-6.7S42.3,7,40.8,5.5c-1.5-1.6-3.5-2.3-6.1-2.3   h-5.1v18C29.6,21.2,34.7,21.2,34.7,21.2z"></path>
        <path d="M50.9,0.1h16.9v3.1H54.3v7.2h12v3h-12v7.8h13.4v3.1H50.9V0.1z"></path>
        <path d="M71.8,0h10.9c2.5,0,4.4,0.7,5.7,2c1.4,1.4,2,3.1,2,5.3s-0.7,4-2,5.3c-1.4,1.4-3.2,2-5.7,2h-7.5v9.6h-3.4V0z M82.4,11.7   c1.5,0,2.6-0.4,3.3-1.1c0.8-0.8,1.1-1.8,1.1-3.2S86.4,5,85.7,4.2C85,3.4,83.9,3,82.4,3h-7.2v8.6L82.4,11.7L82.4,11.7z"></path>
        <path d="M100.7,3.2h-8V0.1h19.6v3.1h-8v21.1h-3.5L100.7,3.2L100.7,3.2z"></path>
        <path d="M100.6,34.5h3.4v24.2h-3.4V34.5z"></path>
        <path d="M107.5,34.5h3.8l7.4,20l7.4-20h3.8l-9,24.2h-4.3L107.5,34.5z"></path>
        <path d="M133.1,34.5H150v3.1h-13.4v7.2h12v3h-12v7.8H150v3.1h-16.9V34.5z"></path>
      </g>
    )
  },

  logoTop: {
    viewBox: `0 0 300 49`,
    inner: (
      <g>
        <path d="M18.8,0.2h7.8l18.8,48.4H38l-4.8-12H11.8l-4.6,12H0L18.8,0.2z M31,30.4l-8.4-22l-8.6,22H31z" />
        <path d="M52.2,0.2h17.2c5,0,9.2,1,12.8,3c3.6,2,6.2,4.8,8,8.4s2.8,8,2.8,13s-1,9.2-2.8,13c-1.8,3.6-4.4,6.4-8,8.4c-3.6,1.8-7.8,3-12.8,3H52.2V0.2z M69.4,42.4c5.2,0,9.2-1.6,12.2-4.6S86,30.2,86,24.4S84.6,14,81.6,11c-3-3.2-7-4.6-12.2-4.6H59.2v36H69.4z" />
        <path d="M101.8,0.2h33.8v6.2h-27v14.4h24v6h-24v15.6h26.8v6.2h-33.6V0.2z" />
        <path d="M143.6,0h21.8c5,0,8.8,1.4,11.4,4c2.8,2.8,4,6.2,4,10.6s-1.4,8-4,10.6c-2.8,2.8-6.4,4-11.4,4h-15v19.2h-6.8V0z M164.8,23.4c3,0,5.2-0.8,6.6-2.2c1.6-1.6,2.2-3.6,2.2-6.4s-0.8-4.8-2.2-6.4C170,6.8,167.8,6,164.8,6h-14.4v17.2L164.8,23.4L164.8,23.4z" />
        <path d="M201.4,6.4h-16V0.2h39.2v6.2h-16v42.2h-7L201.4,6.4L201.4,6.4z" />
      </g>
    )
  },

  logoBottom: {
    viewBox: `0 0 300 49`,
    inner: (
      <g>
        <path d="M201.2,0.3h6.8v48.4h-6.8V0.3z" />
        <path d="M215,0.3h7.6l14.8,40l14.8-40h7.6l-18,48.4h-8.6L215,0.3z" />
        <path d="M266.2,0.3H300v6.2h-26.8v14.4h24v6h-24v15.6H300v6.2h-33.8V0.3z" />
      </g>
    )
  },

  /**
   * Arrows
   */
  arrow: {
    viewBox: `0 0 71 32`,
    inner: (
      <g>
        <polygon points="55.2,0.8 51.7,4.3 60.8,13.5 0,13.5 0,18.5 60.8,18.5 51.7,27.7 55.2,31.2 70.4,16" />
      </g>
    )
  },
  arrowDown: {
    viewBox: `0 0 16 76`,
    inner: (
      <g>
        <path d="M15.1,67.9c-0.4-0.4-1-0.4-1.4,0L9,72.6V0H7v72.6l-4.7-4.7c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l6.4,6.4c0.4,0.4,1,0.4,1.4,0l6.4-6.4C15.5,69,15.5,68.3,15.1,67.9z" />
      </g>
    )
  }
};

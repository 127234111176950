/**
 * Get Item
 */
export function getItem(url, array) {
  if (!url || !Array.isArray(array)) return false;
  const urlSlug = url.split('/')[1];

  let item = false;

  array.forEach(el => {
    if (urlSlug === el.slug) {
      item = el;
    }
  });

  return item;
}

/**
 * Get NextItem
 */
export function getNextItem(url, array, cat) {
  if (!url || !Array.isArray(array)) return false;
  const urlSlug = url.split('/').filter(Boolean)[1];

  let arrayItems = [];

  if (cat) {
    array.forEach((el, i) => {
      if (cat === el.type) {
        arrayItems.push(el);
      }
    });
  } else {
    arrayItems = array;
  }

  let item = false;
  let itemIndex = false;
  arrayItems.forEach((el, i) => {
    if (urlSlug === el.slug) {
      itemIndex = i;
    }
  });

  item =
    itemIndex + 1 === arrayItems.length
      ? arrayItems[0]
      : arrayItems[itemIndex + 1];

  return item;
}

/**
 * Get Categories
 */
export function getCategories(categories) {
  if (!categories) return false;
  return categories.join(' / ');
}

/**
 * Get Authors
 */
export function getAuthors(authors) {
  if (!authors) return false;
  return authors.join(', ');
}

/**
 * Get Link
 */
export function getLink(post) {
  if (!post) return false;
  const { type = false, slug = false } = post;

  return '/' + type.slice(0, -1) + '/' + slug;
}

/**
 * Get Images
 */
export function getImages(array) {
  if (!array) return [];

  var result = Object.keys(array).map(function(key) {
    return array[key];
  });

  return result;
}

/**
 * Merge an array of classNames into a single string
 *
 * @param {array} classNames
 * @returns {string}
 */
export function mergeClassNames(...classNames) {
  const mergedClassNames = [];

  classNames.forEach(className => {
    if (!className || className === '') return;
    mergedClassNames.push(className);
  });

  return mergedClassNames.join(' ');
}

/**
 * Add a reference to an array
 *
 * @param {element} el
 * @param {array} array
 * @returns {undefined}
 */
export function refToArray(el, array) {
  if (!el || !Array.isArray(array) || array.includes(el)) return;
  return array.push(el);
}

/**
 * Bind functions
 *
 * @param {this} self
 * @param {array} functions
 * @returns {string}
 */
export function bind(self, ...functions) {
  functions.forEach(name => {
    self[name] = self[name].bind(self);
  });
}

/**
 * Captilize the first letter of a string
 *
 * @param {string} string
 * @returns {string}
 */
export function capitalize(string) {
  if (string.length === 0) return '';
  return string[0].toLocaleUpperCase() + string.substr(1);
}

/**
 * Generate a random string
 *
 * @param {number} characters of characters in the string
 * @returns {string} random string
 */
export function randomString(characters = 5) {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let rndmStr = '';

  for (let i = 0; i < characters; i++)
    rndmStr += possible.charAt(Math.floor(Math.random() * possible.length));

  return rndmStr;
}

/**
 * Split Text
 */
export function splitText(text) {
  if (!text) return false;

  const splitText = text
    .replace('</p><p>', '<br />')
    .replace('<p>', '')
    .replace('</p>', '')
    .split('<br />');

  return splitText;
}

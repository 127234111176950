import React from 'react';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';

import { getItem } from '_utils';
import { Page, NotFound } from '_views';

import { Loader, Footer, Header, Intro } from '_components';

// Declare your component
class MainRoute extends React.PureComponent {
  state = {
    menuOpen: false,
    intro: true,
    hasIntro: true
  };

  //helpers
  menuToggle = status => {
    this.setState({
      menuOpen: status === 'toggle' ? !this.state.menuOpen : status
    });
  };

  finishIntro = () => {
    this.setState({
      hasIntro: false
    });

    setTimeout(() => {
      this.setState({
        intro: false
      });
    }, 1000);
  };

  componentDidMount() {
    if (this.state.intro) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.menuOpen || this.state.intro) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevState.intro
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { app = false, location = {} } = this.props;
    const { pathname = false } = location;
    const { main = {} } = app;
    const { pages = false, homepage = false, privacyPage = false } = main;

    if (pages) {
      const page =
        pathname === '/'
          ? homepage[0]
          : pathname === '/privacy-policy' || pathname === '/privacy-policy/'
          ? privacyPage[0]
          : getItem(pathname, pages);

      return (
        <div className={this.state.intro ? 'Main has-intro' : 'Main'}>
          {this.state.hasIntro && (
            <Intro
              {...main}
              page={page}
              isHome={pathname === '/' ? true : false}
              finishIntro={this.finishIntro}
            />
          )}

          <Header
            {...main}
            menuOpen={this.state.menuOpen}
            menuToggle={e => this.menuToggle(e)}
          />
          {this.renderInner(page, pathname)}
          <Footer {...main} />
        </div>
      );
    }
    return <Loader />;
  }

  renderInner(page, pathname) {
    if (page) {
      return (
        <Page
          {...page}
          isHome={pathname === '/' ? true : false}
          pathname={pathname}
        />
      );
    } else {
      return <NotFound />;
    }
  }
}

export default MainRoute;

import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.PureComponent {
  // render
  render() {
    const { privacyPage = false, copyright = '' } = this.props;
    return (
      <footer className={`Footer`}>
        {copyright && <div className={'Footer__col'}>{copyright}</div>}
        {privacyPage && (
          <div className={'Footer__col'}>
            <Link to={'/privacy-policy'}>{privacyPage[0].title}</Link>
          </div>
        )}
      </footer>
    );
  }
}
export default Footer;

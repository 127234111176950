import React from 'react';
import { Hero } from '_components';

class NotFound extends React.PureComponent {
  render() {
    return (
      <div className={'Page Page--notFound'}>
        <Hero title={`404`} />
        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    return (
      <div className={`Page__wrap`}>
        <div className={`Page__body`}>
          <div className={`Page__col Page__col--lg Page__text`}>
            Document not found
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
